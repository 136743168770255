import Dexie from 'dexie';
import { Plant } from '../entity/Plant';
import { UserPlant } from '../entity/UserPlant';
import { UserPlantChangelog } from '../entity/UserPlantChangelog';
import { BaseChangelog } from '../entity/BaseChangelog';
import { ScenarioChangelog } from '../entity/ScenariosChangelog';
import { User } from '../entity/User';
import { ReferenceDefault } from '../entity/ReferenceDefault';
import { BasisRegion } from '../entity/BasisRegion';
import { UsdaData } from '../entity/UsdaData';
import { ProductMatrices } from '../entity/ProductMatrices';
import { ProductCategory } from '../entity/ProductCategory';

export const db = new Dexie('biofuel-db');

/**
 * We only specify indexable keys here, then we will map the table to plants
 */
db.version(1).stores({
  plant: '&plantId, plantname',
  userPlant: '&user_plantID',
  userPlantChangelog: '++id',
  baseChangelog: '++id',
  scenarioChangelog: '++id',
  referenceDefault: '++id',
  basisRegion: '++id',
  usdaData: '++id',
  productMatrices: '++id',
  user: '&cognito_username',
});

// New version added product category table
db.version(2).stores({
  plant: '&plantId, plantname',
  userPlant: '&user_plantID',
  userPlantChangelog: '++id',
  baseChangelog: '++id',
  scenarioChangelog: '++id',
  referenceDefault: '++id',
  basisRegion: '++id',
  usdaData: '++id',
  productMatrices: '++id',
  user: '&cognito_username',
  productCategory: '++id',
});

// New version added usda historical data table
db.version(3).stores({
  plant: '&plantId, plantname',
  userPlant: '&user_plantID',
  userPlantChangelog: '++id',
  baseChangelog: '++id',
  scenarioChangelog: '++id',
  referenceDefault: '++id',
  basisRegion: '++id',
  usdaData: '++id',
  usdaHistoricalData: '++id',
  productMatrices: '++id',
  user: '&cognito_username',
  productCategory: '++id',
});

export const plantTable = () => db.table('plant');

export const userPlantTable = () => db.table('userPlant');

export const userPlantChangelogTable = () => db.table('userPlantChangelog');

export const baseChangeLogTable = () => db.table('baseChangelog');

export const scenarioChangelogTable = () => db.table('scenarioChangelog');

export const userTable = () => db.table('user');

export const referenceDefaultTable = () => db.table('referenceDefault');

export const usdaDataTable = () => db.table('usdaData');

export const usdaHistoricalDataTable = () => db.table('usdaHistoricalData');

export const basisRegionTable = () => db.table('basisRegion');

export const productMatricesTable = () => db.table('productMatrices');

export const productCategoryTable = () => db.table('productCategory');

export function dbMapInit() {
  plantTable().mapToClass(Plant);
  userPlantTable().mapToClass(UserPlant);
  userPlantChangelogTable().mapToClass(UserPlantChangelog);
  baseChangeLogTable().mapToClass(BaseChangelog);
  scenarioChangelogTable().mapToClass(ScenarioChangelog);
  userTable().mapToClass(User);
  referenceDefaultTable().mapToClass(ReferenceDefault);
  basisRegionTable().mapToClass(BasisRegion);
  usdaDataTable().mapToClass(UsdaData);
  usdaHistoricalDataTable().mapToClass(UsdaData);
  productMatricesTable().mapToClass(ProductMatrices);
  productCategoryTable().mapToClass(ProductCategory);
}
