/**
 * @param {string} userPlantId
 * @param {Record<string, Record<string, any>} base
 * @param {'create' | 'update' | 'delete'} operation the operation for the
 * change log
 * @param {DateTime} created changelog created time
 */
export function BaseChangelog(userPlantId, base, operation, created) {
  this.userPlantId = userPlantId;
  this.base = base;
  this.operation = operation;
  this.created = created;
}
