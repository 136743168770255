import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './context/auth-context';
import { UserProvider } from './context/user-context';
import NovozymesBold from './assets/fonts/Novozymes-Bold.otf';
import NovozymesNormal from './assets/fonts/Novozymes-Regular.otf';
import NovozymesThin from './assets/fonts/Novozymes-Thin.otf';
import { createGlobalStyle } from 'styled-components';

import './update-notification.css';

const GlobalStyles = createGlobalStyle`
 @font-face {
    font-family: 'Novozymes';
    src: url(${NovozymesBold}) format('opentype');
    font-weight: bold;
    font-style: bold;
  }
     
  @font-face {
    font-family: 'Novozymes';
    src: url(${NovozymesNormal}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
    
  @font-face {
    font-family: 'Novozymes';
    src: url(${NovozymesThin}) format('opentype');
    font-weight: 200;
    font-style: normal;
  }
  html {
    font-family: Novozymes;
  }
`;

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <UserProvider>{children}</UserProvider>
    </AuthProvider>
  );
}

ReactDOM.render(
  <AppProviders>
    <App />
    <GlobalStyles />
  </AppProviders>,
  document.getElementById('root'),
);

function handleUpdateAvailable(registration) {
  // Make sure that any new version of a service worker will take over the page
  // and become activated immediately.
  const waitingServiceWorker = registration.waiting;
  if (waitingServiceWorker) {
    waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
  }

  const link = document.createElement('button');
  link.classList.add('update-notification');
  link.innerHTML = 'A new version of the application is available, please click here to install.';

  link.addEventListener('click', e => {
    e.preventDefault();
    window.location.reload();
  });

  document.querySelector('body').appendChild(link);
}

function handlePWAInstalled() {
  const link = document.createElement('button');
  link.classList.add('update-notification');
  link.innerHTML = 'The application is now available for offline use.';

  link.addEventListener('click', e => {
    link.remove();
  });

  setTimeout(() => {
    link.remove();
  }, 5000);

  document.querySelector('body').appendChild(link);
}

function handleInstallPWA() {
  serviceWorker.register({
    onUpdate: handleUpdateAvailable,
    onSuccess: handlePWAInstalled,
  });
  // TODO: Figure out a button to trigger install later
  // window.addEventListener('beforeinstallprompt', event => {
  //   event.prompt();
  // });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// eslint-disable-next-line no-unused-expressions
process.env.REACT_APP_ENABLE_PWA === 'YES' ? handleInstallPWA() : serviceWorker.unregister();
