/**
 * @param {string} userPlantId
 * @param {{calculation_name: string, data: Record<string, any>}[]} scenario
 * @param {'create' | 'update' | 'delete'} operation the operation for the
 * change log
 * @param {DateTime} created changelog created time
 */
export function ScenarioChangelog(userPlantId, scenario, operation, created) {
  this.userPlantId = userPlantId;
  this.scenario = scenario;
  this.operation = operation;
  this.created = created;
}
