import Promise from 'bluebird';
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID, // e.g. us-east-2_uXboG5pAb
  ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID, //is this used anywhere?
};

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export const LOGIN_FAILURE_REASONS = {
  CONNECTION_ISSUE: 'CONNECTION_ISSUE',
  FAIL_TO_LOGIN: 'FAIL_TO_LOGIN',
  NEW_PASSWORD_CHALLENGE: 'NEW_PASSWORD_CHALLENGE',
};

export function forgetPassword(UserName) {
  const userData = {
    Username: UserName,
    Pool: userPool,
  };

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  return new Promise(function cognitoForgotPasswordPromise(resolve, reject) {
    const events = {
      onSuccess(data) {
        resolve(cognitoUser);
      },
      onFailure(err) {
        reject(err);
      },
    };
    cognitoUser.forgotPassword(events);
  });
}

export function confirmNewPassword({ cognitoUser, verificationCode, newPassword }) {
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: resolve,
      onFailure: reject,
    });
  });
}

export const login = ({ UserName, Password }) => {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  return new Promise((resolve, reject) => {
    try {
      const authenticationData = {
        UserName,
        Password,
      };
      const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      const userData = {
        Username: UserName,
        Pool: userPool,
      };
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function(result) {
          return cognitoUser.getUserAttributes(function(err, results) {
            if (err) {
              return reject({
                reason: LOGIN_FAILURE_REASONS.CONNECTION_ISSUE,
                err,
              });
            }
            const user = results[2];
            localStorage.setItem('user', JSON.stringify(user));
            return resolve(user);
          });
        },
        onFailure: function(err) {
          console.log('***** err: ', err);
          return reject({ reason: LOGIN_FAILURE_REASONS.FAIL_TO_LOGIN, message: err.message });
        },
        // TODO HANDLE NEW PASSWORD REQUIRE HERE Default123456 is new Password need to be parse from front end
        newPasswordRequired: () => {
          console.log('***** newPasswordRequired: ');
          console.log(cognitoUser);
          console.log(cognitoUser.getAuthenticationFlowType(), 'YOU NEED TO CHANGE PASSWORD');
          reject({
            reason: LOGIN_FAILURE_REASONS.NEW_PASSWORD_CHALLENGE,
            err: 'Please input new Password code ',
            cognitoUser,
          });
        },
      });
    } catch (err) {
      return reject(err);
    }
  });
};

export const confirmFirstTimeNewPassword = ({ cognitoUser, password, passwordVerify }) => {
  return new Promise((resolve, reject) => {
    if (password !== passwordVerify) {
      return reject({ message: 'Password do not match' });
    }
    cognitoUser.completeNewPasswordChallenge(
      password,
      {},
      {
        onSuccess: user => {
          localStorage.setItem('user', JSON.stringify(user));
          return resolve(user);
        },
        onFailure: err => {
          console.log(err);
          return reject(err);
        },
      },
    );
  });
};

export const logout = () => {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
  localStorage.clear();
  return Promise.resolve();
};

export const getUser = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      logout();
      resolve();
    }
    cognitoUser.getSession(function(err, session) {
      if (err || !session || !session.isValid()) {
        return logout();
      }
      resolve(cognitoUser);
    });
  });
};
