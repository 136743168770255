import React, { useEffect, useState } from 'react';
import { useUser } from './context/user-context';
import getUser from './getUser';
import LoadingSpinner from './components/LoadingSpinner';
import { db, dbMapInit } from './db';
import authConfig from './authConfig';
import { Amplify } from '@aws-amplify/core';
import { Authenticator } from '@novozymes/auth';

const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: 1, webpackChunkName: "authenticated" */ './authenticated-app'),
);
const UnAuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: 1, webpackChunkName: "unauthenticated" */ './unauthenticated-app'),
);

function App() {
  Amplify.configure(authConfig);
  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <Authenticator amplifyConfig={authConfig}>
        <AuthenticatedApp />
      </Authenticator>
    </React.Suspense>
  );
}

export default App;
