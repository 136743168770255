/**
 * Plant is like a template for user to create their own plant from one
 * This is stored in table 'plant'
 * We can see the data structure of `base` in load_user_full_data.json mock file
 * @param {Record<string, string>} plant
 * @param {Record<string, Record<string, any>} base
 * @param {string} user_plantID
 * @param {{calculation_name: string, data: Record<string, any>}[]} scenarios the array of scenarios,
 * calculation name is unique inside a plant
 */
export function UserPlant(user_plantID, base, scenarios, plant) {
  this.user_plantID = user_plantID;
  this.base = base;
  this.scenarios = scenarios;
  this.plant = plant;
}
