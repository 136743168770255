/**
 * Plant is like a template for user to create their own plant from one
 * This is stored in table 'plant'
 * @param {string} plantId
 * @param {string} plantname
 * @param {string} state
 * @param {string} country
 * @param {string} region_basis
 */
export function Plant(plantId, plantname, state, country, region_basis) {
  this.plantId = plantId;
  this.plantname = plantname;
  this.state = state;
  this.country = country;
  this.region_basis = region_basis;
}
