import React, { useState } from 'react';
import {
  getUser,
  login,
  logout,
  forgetPassword,
  confirmNewPassword,
  confirmFirstTimeNewPassword,
} from '../screens/AuthenticationScreens/aws-cognito';
import { useAsync } from 'react-async';
import LoadingSpinner from '../components/LoadingSpinner';

const AuthContext = React.createContext();

async function bootstrapAppData() {
  const user = await getUser();
  return {
    user,
  };
}

function AuthProvider(props) {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);

  const { data = { user: null }, isRejected, isPending, isSettled, error, reload } = useAsync({
    promiseFn: bootstrapAppData,
  });

  const [forgotPasswordUser, setForgotPasswordUser] = useState(null);

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <LoadingSpinner />;
    }
    if (isRejected) {
      return (
        <div>
          <p>Uh oh... There's a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      );
    }
  }

  const loginForm = ({ UserName, Password }) => login({ UserName, Password }).then(reload);
  const logoutForm = () => logout().then(reload);
  const forgetPasswordForm = email =>
    forgetPassword(email).then(cognitoUser => {
      setForgotPasswordUser(cognitoUser);
      return cognitoUser;
    });
  const confirmNewPasswordForm = ({ cognitoUser, verificationCode, newPassword }) =>
    confirmNewPassword({ cognitoUser, verificationCode, newPassword });
  const confirmFirstTimeNewPasswordForm = ({ cognitoUser, password, passwordVerify }) => {
    return confirmFirstTimeNewPassword({ cognitoUser, password, passwordVerify }).then(reload);
  };

  return (
    <AuthContext.Provider
      value={{
        data,
        forgotPasswordUser,
        loginForm,
        logoutForm,
        forgetPasswordForm,
        confirmNewPasswordForm,
        confirmFirstTimeNewPasswordForm,
      }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(AuthContext);

function withAuth(WrappedComponent) {
  return function WithThemeComponent(props) {
    const auth = useAuth();
    return <WrappedComponent auth={auth} {...props} />;
  };
}
export { AuthProvider, useAuth, withAuth };
